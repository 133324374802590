.info {
  background-color: rgb(240, 247, 255);
  border: 1px solid rgb(224, 227, 231);
  border-radius: 10px;
  color: rgb(0, 114, 229);
  font-size: 0.75rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

.log {
  background-color: rgb(240, 247, 255);
  border: 1px solid rgb(224, 227, 231);
  border-radius: 10px;
  color: rgb(0, 114, 229);
  font-size: 0.75rem;
  max-height: 100px;
  overflow-y: scroll;
  margin-bottom: 1rem;
  padding: 1rem;
}

/*#googlesearch {
  display: none;
}*/
